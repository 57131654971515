
.SupplyChain {
    display: flex;
    background: var(--blue-mid);
    color: white;
    padding: 0;
}

.SupplyChain p {
    color: white;
}

.SupplyChain__Left {
    flex: 1;
}

.SupplyChain__Left img {
    width: 100%;
}

.SupplyChain__Right {
    flex: 1;
    padding: 32px;
}

@media only screen and (max-width: 767px) {
    .SupplyChain__Left {
        display: none;
    }
    .SupplyChain__Right {
        padding: 64px;
    }
}

@media only screen and (max-width: 480px) {

    .SupplyChain__Right {
        padding: 32px;
    }

}