
.Contact__Content {
    display: flex;
}

.Contact__Content__Left {
    flex: 1;
    padding: 32px;
}

.Contact__Content__Right {
    flex: 1;
    padding: 32px;
}

.Contact ul {
    padding: 0;
}

.Contact li {
    list-style-type: none;
    margin-bottom: 12px;
}

.Contact a {
    display: flex;
    align-items: center;
    width: fit-content;
    text-decoration: none;
    color: initial;
    transition: 250ms color;
}
.Contact a path,
.Contact a circle {
    transition: 250ms stroke;
}

.Contact a:hover {
    color: var(--blue-mid);
}

.Contact a:hover path,
.Contact a:hover circle {
    stroke: var(--blue-mid);
}

.Contact svg {
    width: 16px;
    margin-right: 8px;
}

.Contact hr {
    margin: 32px 0;
}

@media only screen and (max-width: 767px) {
    .Contact__Content {
        flex-direction: column;
    }

    .Contact__Content__Left {
        padding: 16px;
    }

    .Contact__Content__Right {
        padding: 16px;
    }

}

@media only screen and (max-width: 480px) {

    .Contact__Content__Left {
        padding: 0;
    }

    .Contact__Content__Right {
        padding: 0;
    }

}
