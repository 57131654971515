
.One {
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  align-items: center;
  height: 100vh;
  margin-top: 32px;
}

.One h1 {
  padding: 0 32px;
  text-align: center;
  color: white;
}

.One__Download {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  padding: 32px 0;
}