.code-verification-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue-dark);
  border: none;
  color: var(--white);
  border-radius: 6px;
  width: 100%;
  padding: 1rem 0;
  margin-top: 1rem;
  cursor: pointer;
}

img[alt="unlock"] {
  margin: 0 1rem;
}