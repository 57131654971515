
.Top {
    display: flex;
    justify-content: center;
    min-height: 512px;
    padding: 0;
    background-color: var(--blue-dark);
    user-select: none;
}

.Top__Form {
    flex: 1;
    width: 448px;
    min-width: 448px;
    max-width: 448px;
    padding: 0px 32px;
}

.Top__Form img {
    width: 100%;
    padding: 32px;
    padding-bottom: 0;
}

.Top__Form h3 {
    margin: 0;
    padding: 16px 0;
    font-size: 28px;
    font-weight: 300;
    color: white;
    text-align: center;
}

.Top__Image {
    flex: 2;
}

@media only screen and (max-width: 767px) {
    .Top {
        flex-direction: column;
    }
    .Top__Form {
        margin: 0 auto;
    }
}

@media only screen and (max-width: 454px) {

    .Top__Form {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
    }
    .Top__Form img {
        padding: 0;
        padding-top: 32px;
    }
    .Top__Form h3 {
        padding: 8px 0;
    }
    .Taghub-Login {
        border: none;
        padding: 0;
        padding-top: 12px;
        padding-bottom: 8px;
    }
}
