.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.1rem;
}

.input span {
  font-size: .875rem;
  line-height: 1.3125rem;
}

.input-field input {
  border-radius: 4px;
  border: 1px solid #031F35;
  padding: 0 .675rem;
  width: 100%;
  height: 100%;
}

.input-field {
  position: relative;
  width: 100%;
  height: 2.375rem;
}

.input-field img {
  position: absolute;
  right: .8125rem;
}

.input-field img[alt="eye"] {
  cursor: pointer;
  width: 1rem;
  top: calc(50% - .5rem);
}

.input-field img[alt="icon"] {
  width: 2rem;
  top: calc(50% - .8rem);
}