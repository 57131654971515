
.Sharing {
    padding: 48px 0;
}

.Sharing__Text {
    display: flex;
    padding: 64px;
    padding-top: 0;
}

.Sharing__Text p {
    flex: 1;
    padding: 32px;
    padding-top: 0;
}

.Sharing__Overview {
    position: relative;
    display: flex;
}

.Sharing__Overview__Image {
    position: absolute;
    right: 64px;
    top: 0;
    width: 50%;
    height: 100%;
    padding: 128px 0;
}

.Sharing__Overview__Text {
    width: 300px;
    margin: 64px 0;
    margin-left: 10vw;
    padding: 16px 32px;
    border: 1px solid #eee;
    border-radius: 4px;
    font-size: 15px;
    background: white;
    z-index: 1;
}

@media only screen and (max-width: 767px) {
    .Sharing__Text {
        flex-direction: column;
    }
    .Sharing__Overview__Image {
        width: 100%;
        right: 0;
    }
    .Sharing__Overview__Text {
        margin: 32px auto;
    }
}

@media only screen and (max-width: 480px) {
    .Sharing__Text {
        padding: 0;
    }
    .Sharing__Overview__Image {
        display: none;
    }
}
