
.Products {
    height: 956px;
}

.Products__Content {
    display: flex;
    justify-content: center;
    height: 100%;
}

.Products img {
    height: 800px;
    margin-top: -24px;
}

.Products__List {
    display: none;
}

.Product {
    margin-bottom: 32px;
}

.Product__Title {
    margin: 4px 0;
    font-weight: bold;
    color: var(--blue-mid);
    text-transform: uppercase;
}

.Product__Description {
    line-height: 24px;
    color: #444;
}


@media only screen and (max-width: 960px) {

    .Products {
        height: 820px;
    }

    .Products img {
        height: 660px;
    }
}

@media only screen and (max-width: 810px) {
    .Products {
        height: initial;
    }
    .Products img {
        display: none;
    }

    .Products__List {
        display: block;
    }
}