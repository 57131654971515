
.Benefits {
    background: var(--blue-mid);
    color: #fff;
}

.Benefits h2 { 
    color: #fff;
}

.Benefits h2:after {
    border-color: #fff;
}

.Benefits__Items {
    display: flex;
}

.Benefits__Item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 32px;
}

.Benefits__Item__Title {
    font-size: 20px;
    margin-bottom: 16px;
    text-align: center;
}

.Benefits__Item svg, 
.Benefits__Item path {
    stroke: #fff;
}

.Benefits__Item__Description {
    text-align: center;
}

.Benefits__Item__Image {
    margin-bottom: 16px;
    height: 80px;
    display: flex;
    align-items: center;
}


@media only screen and (max-width: 767px) {

    .Benefits__Items {
        flex-direction: column;
    }

}