.billing-plans {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.plans-wrapper {
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
  grid-gap: .8rem;
  margin-top: 1.5rem;
}

.plans-enterprise {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 5px;
  margin-top: 10px;
  font-size: 25px;
}

.contact-link {
  color: #59d;
  text-decoration: none;
}

.plans-enterprise .contact-link:hover {
  color: var(--blue-light);
}

.loading {
  border: 2px solid #CCE9FF;
  border-radius: 50%;
  border-top: 2px solid #59d;
  height: 45px;
  width: 45px;
  animation: spin 2s linear infinite;
  margin: auto;
  position: absolute;
  top: 50%;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}