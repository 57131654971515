
.Footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px;
    height: 128px;
    background: var(--blue-dark);
    color: white;
}

.Footer__Left, .Footer__Right {
    margin: 0 24px;
}

.Footer svg {
    height: 32px;
    margin-right: 8px;
    fill: #fff;
    transition: 250ms fill;
}

.Footer svg:hover {
    fill: var(--blue-mid);
    cursor: pointer;
}

.Footer a {
    text-decoration: none;
    color: #fff;
    transition: 250ms color;
}

.Footer a:hover {
    color: var(--blue-mid);
}

.Footer__Right span {
    margin-left: 32px; 
}


@media only screen and (max-width: 600px) {

    .Footer {
        flex-direction: column;
        align-items: flex-start;
    }
    
    .Footer__Left, .Footer__Right {
        margin: 0;
    }

    .Footer__Left {
        margin-left: -2px
    }

    .Footer__Right {
        display: flex;
        width: 100%;
        /*justify-content: space-between;*/
    }

    .Footer__Right span { 
        flex: 1;
        margin: 0;
    }

    .Footer__Right span:last-child {
        text-align: right;
    }

}

@media only screen and (min-width: 1484px) {
    .Footer {
        padding-left: 0;
        padding-right: 0;
    }
}