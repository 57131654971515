.personal-info-form {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - .625rem));
  grid-column-gap: 1.25rem;
}

.personal-info select {
  width: 100%;
  padding: .5rem;
  font-size: .875rem;
  line-height: 1.3125rem;
  border-radius: 4px;
  background-color: var(--white);
  margin-bottom: 1rem;
}

.select-field {
  position: relative;
  width: 100%;
  height: 2.375rem;
}

span {
  font-size: .875rem;
  line-height: 1.3125rem;
  margin-bottom: 0.1rem;
}

select {
  border-radius: 4px;
  border: 1px solid #031F35;
  height: 2.375rem;
  -webkit-appearance:none;
}

.disabled {
  background-color: #999999;
  cursor: not-allowed;
}