
header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: var(--header-height);
    padding: 0 16px;
    background: var(--blue-dark);
    color: white;
    user-select: none;
    --transition: all 300ms;
}

header .HeaderLeft {
    height: 100%;
    padding: 12px 0;
}

header .HeaderLeft img {
    height: 100%;
    margin-right: 16px;
    padding-top: 4px;
}

header .HeaderRight {
    display: flex;
    align-items: center;
    height: 100%;
}

header .HeaderRight a {
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 8px;
    border-bottom: 2px solid transparent;
    transition: var(--transition);
    text-decoration: none;
    color: white;
}

header .HeaderRight a:hover {
    border-bottom: 2px solid white;
    cursor: pointer;
    background: var(--blue-mid);
}

header .HeaderRight svg {
    height: 24px;
}

header .HeaderRight path {
    transition: var(--transition);
}

header .HeaderRight svg:hover {
    cursor: pointer;
}

header .HeaderRight svg:hover path {
    fill: #fff;   
}

header .MenuItems {
    position: absolute;
    top: var(--header-height);
    right: 0;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 8px 0;
    overflow: hidden;
    background: var(--blue-dark);
    box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

header .MenuItems a {
    width: 100%;
    padding: 12px 32px;
    border: none;
    border-left: 2px solid transparent;
}

header .MenuItems a:hover {
    border: none;
    border-left: 2px solid white;
    background: var(--blue-mid);
    cursor: pointer;
}

header .MenuItem__Demo {
    color: var(--green-light) !important;
}

header .MenuItem__Demo:hover {
    color: white !important;
    background: var(--green-mid) !important;
}

@media only screen and (min-width: 1484px) {
    header {
        padding-left: 0;
        padding-right: 0;
    }
}