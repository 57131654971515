.sign-up {
  height: calc(100% - var(--header-height));
  background-color: #fff;
  overflow: scroll;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

.sign-up::-webkit-scrollbar {
    display: none;
}

.full-wrap {
  padding: 20px 0;
}

.center-wrap {
  padding: 20px calc(50% - 300px);
}

.signup-form-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
}

.signup-title {
  font-size: 2.25rem;
  line-height: 3.375rem;
  text-align: center;
  font-weight: bold;
  color: var(--blue-dark);
  margin: 1rem 0 .3125rem;
}

.signup-description {
  color: lightgray;
  margin: 0;
  margin-bottom: .75rem;
  text-align: center;
}

.signup-input-block {
  position: relative;
  display: flex;
  width: 100%;
  height: 3.25rem;
  background-color: lightgray;
}

.signup-next-step-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border: none;
  border-radius: 4px;
  color: var(--white);
  font-size: .875rem;
  line-height: 1.3125rem;
  padding: .75rem 0;
}

.disabled-button {
  background-color: #999999;
  cursor: not-allowed;
}

.enabled-button {
  background-color: var(--blue-dark);
  cursor: pointer;
}

.policies {
  margin-top: 15px;
  font-size: 12px;
  text-align: center;
}

.policies a {
  color: var(--blue-mid)
}

.cancel-button {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: #dc2727;
  border: none;
  border-radius: 4px;
  color: var(--white);
  font-size: .875rem;
  line-height: 1.3125rem;
  padding: .75rem 0;
  cursor: pointer;
  margin-top: 1rem;
}

.error-message {
  font-weight: bold;
  line-height: 28px;
  color: #dc2727;
  font-size: .875rem;
  text-align: center;
  margin-bottom: 0.75rem;
}