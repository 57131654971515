.progress {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.25rem;
}

.progress::after {
  content: '';
  height: .2rem;
  position: absolute;
  bottom: calc(1.25rem - .2rem/2);
  left: 2.5rem;
  width: calc(100% - 5rem);
  background-color: #999;
}

.progress-last::after {
  background-color: #000;
}

.step {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.last-step {
  align-items: flex-end;
}

.step span {
  font-size: .875rem;
  line-height: 1.25rem;
  color: #999;
  margin-bottom: 8px;
}

.step div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: #999;
}

.selected span {
  color: #000;
}

.selected div {
  background-color: #000;
  color: var(--white);
}
