.success {
  width: 100%;
  height: calc(100% - var(--header-height));
  background-color: white;
  padding: 1.25rem 10vw;
}

.success-title {
  font-weight: bold;
}

.link-to-login {
  color: var(--blue-mid);
  text-decoration: none;
}

.link-to-login:hover {
  color: var(--green-mid);
}