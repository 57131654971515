
.Technology__Items {
    display: flex;
    padding-top: 32px;
}

.Technology__Item {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.Technology__Item__Title {
    text-transform: uppercase;
    font-weight: 500;
    color: var(--blue-dark);
}

.Technology__Item__Image {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 128px;
    height: 128px;
    border-radius: 50%;
    margin: 24px 0;
}

.Technology__Item__Image g, 
.Technology__Item__Image path {
    stroke: #fff;
}

.Technology__Item__Image qr-code {
    margin-left: 32px;
    margin-top: 32px;
}

.Technology__Item__Description {
    text-align: center;
    padding: 0 16px;
    height: 80px;
    line-height: 24px;
}


@media only screen and (max-width: 767px) {
    .Technology__Items {
        flex-direction: column;
    }
}