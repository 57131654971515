
.Home {
    height: calc(100% - var(--header-height));
    overflow: scroll;
    scroll-behavior: smooth;
    -webkit-overflow-scrolling: touch;
}

.Home::-webkit-scrollbar {
    display: none;
}