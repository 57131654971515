.billing-plan-wrap {
  position: relative;
  display: flex;
  flex-direction: column;
}

.billing-plan {
  border: 1px solid #333;
  border-radius: 6px;
  padding: 1.5rem 1rem;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  height: 100%;
  margin-bottom: 25px;
}

.billing-recommended-plan {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
  border: none;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.billing-recommended {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  padding: .4rem 0 2rem;
  top: -1.8rem;
  width: 100%;
  left: 0;
  background-color: var(--blue-dark);
  color: var(--white);
  border-radius: 6px;
  text-transform: uppercase;
  font-size: .8rem;
  z-index: 0;
}

.billing-title {
  font-size: 1.625rem;
  line-height: 2.375rem;
  font-weight: bold;
}

.billing-price {
  font-size: 2.625rem;
  line-height: 3.875rem;
  color: var(--blue-mid);
  font-weight: bold;
}

.billing-currency {
  font-size: 1rem;
  line-height: 1.5rem;
}

.billing-signup-button {
  background-color: #CCE9FF;
  color: var(--blue-mid);
  font-weight: bold;
  border: 1px solid var(--blue-mid);
  width: 100%;
  padding: 1rem 0;
  border-radius: 4px;
  margin: 1.5rem 0;
  cursor: pointer;
  font-size: 0.875rem;
}

.billing-read-more {
  text-decoration: underline;
  color: var(--blue-mid);
  cursor: pointer;
  text-align: left;
  font-size: .75rem;
  line-height: 1.125rem;
  font-weight: 500;
  bottom: 1.5rem;
  left: 1.1rem;
  position: absolute;
}

.read-more {
  display: inline-block;
}

.plan-description {
  width: 80%;
  display: none;
  color: white;
  border-radius: 4px;
  padding: 1rem;
  background: var(--blue-dark);
  font-size: 12px;
  bottom: -2rem;
  right: -1.5rem;
  z-index: 2;
  position: absolute;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.2);
}

.read-more:hover .plan-description {
  display: block;
}