* {
    box-sizing: border-box;
}

html, body, #root, .App {
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    overflow: hidden;
    background: var(--blue-dark);
    font-family: 'IBM Plex Sans', "helvetica neue", HelveticaNeue, Helvetica-Neue, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Sizes */
    --max-width: 1484px;
    /* Palette */
    --blue-dark: #28383d;
    --blue-mid: #59d;
    --blue-light: rgb(171, 205, 229);
    --green-mid: #3d9970;
    --green-light: #2ebc4f;
    --red-error: #f36f5a;
    --white: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

input, textarea, button {
    outline: none;
}

h2 {
    text-align: center;
    font-size: 30px;
    font-weight: 300;
}

h2::after {
    display: block;
    margin: 0 auto;
    content: "";
    width: 40px;
    padding-top: 16px;
    border-bottom: 2px solid var(--blue-mid);
}

h3 {
    font-size: 24px;
    font-weight: 400;
}

header {
    max-width: var(--max-width);
    margin: 0 auto;
}

section {
    max-width: var(--max-width);
    margin: 0 auto;
    padding: 48px 64px;
    background: white;
}

footer {
    max-width: var(--max-width);
    margin: 0 auto;
}

p {
    line-height: 28px;
    text-align: justify;
    color: #333;
}

em {
    font-style: initial;
    text-decoration: underline;
    text-decoration-color: var(--blue-mid);
}

ul {
    list-style-type: circle;
    padding-left: 32px;
}


@media only screen and (max-width: 480px) {
    section {
        padding: 24px 32px;
    }
}
