.signup-input-block input {
  background-color: lightgray;
  border: none;
  width: 100%;
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

.email-verification-disabled-input {
  color: #666666;
}

.email-button {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  height: 100%;
  border: none;
  padding: .8125rem 30px;
  border-radius: 6px;
  color: var(--white);
  font-size: .875rem;
  line-height: 1.3125rem;
}

button img {
  margin-left: .375rem;
}

.signup-form-wrapper .EmailIcon {
  height: 3.25rem;
  width: 3.5rem;
  background-color: lightgray;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 0 1rem;
}