
.Quality h3 {
  margin-top: 48px;
}

.Quality__Certifications,
.Quality__Security {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.Quality .Certification,
.Quality .Security {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 20px;
  box-shadow: 0 1px 2px rgba(0,0,0,0.15);
}

.Quality .Certification .description,
.Quality .Security .description {
  background-color: var(--blue-mid); 
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  font-size: 0.8em;
  line-height: 0.8em;
}

.Quality__CertificationList {
  display: flex;
  flex-wrap: wrap;
}

.Quality .Certification img,
.Quality .Security img {
  width: 200px;
  margin: 10px;
  transition: all 200ms ease-in-out;
}

.Quality .Certification img:hover,
.Quality .Security img:hover  {
  transform: scale(1.02, 1.02);
}
