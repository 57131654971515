.included-wrap {
  width: 100%;
}

.included-header {
  display: flex;
  justify-content: space-between;
}

.desc {
  color: #999;
  text-transform: uppercase;
  font-size: .625rem;
  line-height: 1rem;
}

.included-wrap ul li {
  padding: 0 8px;
  font-size: .875rem;
  line-height: 1.375rem;
}

.included-wrap ul li::marker {
  content: '•';
}

ul {
  margin-top: 0.5rem;
}