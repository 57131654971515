
.About p {
    text-align: center;
}


@media only screen and (max-width: 767px) {

    .About p {
        text-align: justify;
    }

}